import {
  parse,
  isValid,
  differenceInYears,
  differenceInMonths,
  differenceInDays,
  addYears,
  addMonths,
  sub,
} from 'date-fns';
import { formatDate } from '@/util/date';
import { Validation } from '@/validation/validation';

// fix this when Eslint supports named tuples
type AgeTuple = number[];

function getAge(date: Date): AgeTuple {
  const result: AgeTuple = [0, 0, 0];
  const now = new Date();
  let age = date;

  const years = differenceInYears(now, age);
  if (years > 0) {
    result[0] = years;
    age = addYears(date, years);
  }

  const months = differenceInMonths(now, age);
  if (months > 0) {
    result[1] = months;
    age = addMonths(age, months);
  }

  const days = differenceInDays(now, age);
  if (days > 0) {
    result[2] = days;
  }

  return result;
}

function ageAbove(date: Date, compare: number): boolean {
  const [years, months, days] = getAge(date);

  if (years > compare) {
    return true;
  }

  // If the age is equal to compare, check if it has more months or days
  return !!(years === compare && (months || days));
}

function ageBelow(date: Date, compare: number): boolean {
  const [years] = getAge(date);
  return years < compare;
}

class Dob extends Validation {
  private name = 'Date of birth';
  upperLimit = 130; // more than 130 years old
  lowerLimit = 16; // less than 16 years old

  get isValidError() {
    return `${this.name} must be a valid date`;
  }

  get isRequiredError() {
    return `${this.name} is required`;
  }

  get upperLimitError() {
    return `Hmm, are you sure you are over ${this.upperLimit} years old?`;
  }

  get lowerLimitError() {
    return `Sorry, you must be over ${this.lowerLimit} years old`;
  }

  isValid(date: Date|number|string): boolean | string {
    // Dob is optional
    if (!date) {
      return true;
    }

    let d: Date;

    switch (typeof date) {
      case 'string':
        d = parse(date, 'dd/MM/yyyy', new Date());
        break;
      case 'number':
        d = new Date(date);
        break;
      default:
        d = date;
    }

    if (!isValid(d)) {
      return this.isValidError;
    }

    if (ageAbove(d, this.upperLimit)) {
      return this.upperLimitError;
    }

    if (ageBelow(d, this.lowerLimit)) {
      return this.lowerLimitError;
    }

    return true;
  }

  optionsFn(dateString: string): boolean {
    const date = new Date(dateString);

    return ageAbove(date, this.lowerLimit) && ageBelow(date, this.upperLimit);
  }

  get defaultDob() {
    const d = new Date();
    const diff = sub(d, {
      years: this.lowerLimit,
      days: 1,
    });
    return formatDate(diff.getTime());
  }
}

export default new Dob();
