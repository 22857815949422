<template>
  <q-input
    v-bind="$attrs"
    dense
    outlined
    clearable
    mask="##/##/####"
    hint="Mask: DD/MM/YYYY"
    no-error-icon
    :value="displayValue"
    @input="$emit('input', $event || defaultValue)"
    :rules="[ validation ]"
    :error-message="errorMessage"
    :error="!!errorMessage"
    debounce="500"
  >
    <template #append>
      <q-icon name="event" class="cursor-pointer">
        <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
          <q-date
            minimal
            :value="displayValue"
            mask="DD/MM/YYYY"
            @input="$emit('input', $event || defaultValue)"
            :options="optionsFn"
            :rules="['date']"
            :error-message="errorMessage"
            :error="!!errorMessage"
            lazy-rules="ondemand"
          >
            <div class="row items-center justify-end">
              <q-btn v-close-popup label="Close" color="primary" flat />
            </div>
          </q-date>
        </q-popup-proxy>
      </q-icon>
    </template>
  </q-input>
</template>

<script>
import dobValidation from '@/validation/dob';
import { formatDate } from '@/util/date';

export default {
  name: 'FieldDate',
  props: {
    value: [String, Number],
    errorMessage: String,
    required: Boolean,
    type: {
      type: String,
      default: 'date',
      validator(v) {
        return v === 'date' || v === 'dob';
      },
    },
  },
  computed: {
    displayValue() {
      if (typeof this.value === 'number') {
        return formatDate(this.value);
      }
      return this.value;
    },
    defaultValue() {
      if (this.type === 'dob') {
        return dobValidation.defaultDob;
      }
      return null;
    },
  },
  methods: {
    optionsFn(date) {
      if (this.type === 'dob') {
        return dobValidation.optionsFn(date);
      }
      return true;
    },
    validation(val) {
      if (this.type === 'dob') {
        return dobValidation.isValid(val);
      }

      if (this.required && !val) {
        return 'Date is required';
      }
      return true;
    },
  },
};
</script>
